// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// custom fonts
@import 'fonts';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// customs styles
@import 'custom';

// flatpickr
@import "~flatpickr/dist/flatpickr.css";

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "~sweetalert2/src/sweetalert2";

