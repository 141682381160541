/**
 * Fonts
*/

@font-face {
  font-family: 'Avenir Next LT';
  src: url("/fonts/Avenir-Next-LT-Pro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next LT';
  src: url("/fonts/Avenir-Next-LT-Pro-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next LT';
  src: url("/fonts/Avenir-Next-LT-Pro-Demi.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir Next LT';
  src: url("/fonts/Avenir-Next-LT-Pro-Demi-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'Avenir Next LT';
  src: url("/fonts/Avenir-Next-LT-Pro-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
