// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

/**
 * Variables
*/

$primary: #F9596D;
$secondary: #1C3997;
$info: #2AC4EE;
$dark: #58566B;

$bg-light-gray: #F2F5FB;
$bg-dark-gray: rgba(189, 199, 219, 0.2);

$navbar-height: 93px;

$box-shadow: 0px 10px 40px rgba(189, 199, 219, 0.3);

$btn-border-radius: 0;
$btn-padding-y: .65rem;
$btn-padding-x: 1.2rem;
$btn-font-weight: 600;
$btn-border-width: 2px;

$btn-light-info: rgba(42, 196, 238, 0.1);
$btn-light-danger: rgba(220, 53, 69, 0.1);
$btn-light-secondary: rgba(28, 57, 151, 0.1);
$btn-light-primary: rgba(249, 89, 109, 0.1);
$btn-light-dark: rgba(88, 86, 107, 0.1);
$btn-light-success: rgba(21, 115, 71, 0.1);
$btn-light-warning: rgba(255, 193, 7, 0.1);

$input-bg: rgba(189, 199, 219, 0.2);
$input-border-radius: 0;
$input-border-width: 0;
$input-btn-padding-y: .65rem;
$input-btn-padding-x: 1.2rem;
$input-placeholder-color: #adb5bd;
$input-focus-width: 1px;
$input-focus-box-shadow: 0 0 0 0.25rem rgba(28, 57, 151, .15);

$input-group-addon-bg: #e7ebf2;
$input-group-addon-font-weight: 600;

$form-check-input-border-radius: 0;
$form-check-input-border: 0;
$form-check-input-width: 1.25em;
$form-check-min-height: 1.25em;

$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-inner-padding: 2rem;
