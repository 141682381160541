//
// General
//

body {
    font-family: "Avenir Next LT";
    font-style: normal;
    color: $dark;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    background-color: white;
}

.container-xxl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media-breakpoint-down(md) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

main {
    margin-bottom: auto;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-dark-50 {
    color: $dark;
    opacity: .5;
}

.text-dark-75 {
    color: $dark;
    opacity: .75;
}

h1, h2 {
    font-weight: 700;
}

.h1, .h2 {
    font-weight: 700;
}

.h3, .h4, .h5, .h6 {
    font-weight: 600;
}

h1, .h1 {
    font-size: calc(1.375rem + 1.5vw);

    @include media-breakpoint-up(x1) {
        font-size: 2.5rem;
    }
}

h2, .h2 {
    font-size: calc(1.325rem + 0.9vw);

    @include media-breakpoint-up(x1) {
        font-size: 2rem;
    }
}

h3, .h3 {
    font-size: calc(1.3rem + 0.6vw);

    @include media-breakpoint-up(x1) {
        font-size: 1.75rem;
    }
}

h4, .h4 {
    font-size: calc(1.275rem + 0.3vw);

    @include media-breakpoint-up(x1) {
        font-size: 1.5rem;
    }
}

h5, .h5 {
    font-size: 1.25rem;
}

h6, .h6 {
    font-size: 1rem;
}

.display-heading-wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-between;

    .techlib-logo {
        height: 50px;
        margin-left: 2rem;
    }
}

.display-heading {
    color: $secondary;
    margin-bottom: 0;
}

.display-subheading {
    color: $dark;
}

.display-card-subheading {
    color: $dark;
}

hr {
    background-color: $gray-200;
    opacity: 1;
    border-top-width: 0 !important;
}

a {
    color: $secondary;
    transition: color .25s;
}

.smaller {
    font-size: 0.75rem;
}

.modal {
    .modal-body {
        padding-top: 1rem;
        padding-bottom: .5rem;
    }

    .modal-title {
        line-height: normal;
        font-size: 1.85rem;
    }
}

.form-switch {
    .form-check-label {
        margin-top: 2px;
    }
}

.form-control[readonly] {
    background-color: rgba(189, 199, 219, 0.2);
}

.form-control.flatpickr-input {
    font-weight: 600;
    color: $dark;
}

.flatpickr-months .flatpickr-month {
    border-radius: 0 !important;
}

.border-table {
    border: 1px solid $gray-200;
    padding: 1rem;
}

.mega-icon {
    font-size: 4.75rem;
    color: $primary;
    margin-bottom: 2.5rem;
}

.btn, .btn-close {
    &:focus {
        box-shadow: none;
    }
}

.btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-sm {
    border-radius: 0;

    &.btn-center {
        padding: 0 0.75rem;
        font-size: 1rem;
    }
}

.dropdown-toggle.w-100 {
    text-align: left;

    &::after {
        position: absolute;
        right: 15px;
        top: 17px;
    }
}

.dropdown-menu {
    border-radius: 0;
    z-index: 10000;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $secondary;
}

.btn-transparent {
    background-color: transparent;
    border: none;
}

.btn-link {
    text-decoration: none;
    font-weight: 600;
}

.btn-pill {
    background-color: $bg-dark-gray;
    border-radius: 50px;
    padding: 0.7rem 1.2rem;
    line-height: 1;
}

.btn-info, .btn-primary {
    color: white !important;

    &:hover {
        color: white;
    }
}

.btn-main {
    text-transform: uppercase;
    width: 100%;
    font-weight: 700;
    letter-spacing: .75px;
}

.btn-outline-primary {
    color: $primary;

    &:hover {
        color: white;
    }
}

.btn-outline-info {
    color: $info;

    &:hover {
        color: white;
    }
}

.btn-light-info {
    background-color: $btn-light-info;
    color: $info;

    &:hover {
        color: white;
        background-color: $info;
    }
}

.btn-light-danger {
    background-color: $btn-light-danger;
    color: $danger;

    &:hover {
        color: white;
        background-color: $danger;
    }
}

.btn-light-secondary {
    background-color: $btn-light-secondary;
    color: $secondary;

    &:hover {
        color: white;
        background-color: $secondary;
    }
}

.btn-light-primary {
    background-color: $btn-light-primary;
    color: $primary;

    &:hover {
        color: white;
        background-color: $primary;
    }
}

.btn-light-dark {
    background-color: $btn-light-dark;
    color: $dark;

    &:hover {
        color: white;
        background-color: $dark;
    }
}

.btn-light-success {
    background-color: $btn-light-success;
    color: $success;

    &:hover {
        color: white;
        background-color: $success;
    }
}

.btn-light-warning {
    background-color: $btn-light-warning;
    color: $warning;

    &:hover {
        color: white;
        background-color: $warning;
    }
}

.text-icon-tile {
    width: 22px;
}

.card {
    border-radius: 0;
    border: 1px solid $gray-200;

    &.card-shadow {
        border: none;
        box-shadow: $box-shadow;
    }
    .card-header {
        padding: 2rem;
        background-color: white;
        border: none;

        @include media-breakpoint-down(md) {
            padding: 1.5rem;
        }
    }

    .card-body {
        padding: 2rem;

        @include media-breakpoint-down(md) {
            padding: 1.5rem;
        }
    }
}

.card-outline {
    border: 1px solid $gray-200 !important;
    background-color: transparent;
}

.form-select-transparent {
    width: auto;
    color: $dark;
    // background-color: transparent;
    padding: .5rem 2.75rem .5rem 1.45rem;

    &:focus {
        box-shadow: none;
    }
}

.form-select-pill {
    width: auto;
    border-radius: 50px;
    color: $dark;
}

.form-label.required, .form-check-label.required, .form-check-label.required {
    &::after {
        content:" *";
        color:red;
    }
}

.form-label.required-before, .form-check-label.required-before, .form-check-label.required-before {
    &::before {
        content:" *";
        color:red;
    }
}

.form-control:focus.is-valid, .was-validated .form-control:invalid:focus {
    background-image: none;
    padding-right: inherit;
}

.bg-gray {
    background-color: $bg-dark-gray;
}

.clamp-to-2 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.nav-pills {
    &.nav-pills-rounded {
        background-color: $bg-dark-gray;
        border-radius: 50px;
        padding: 7px;

        .nav-link {
            border-radius: 50px;
            padding: .5rem 1rem;
            font-weight: 400;
        }
    }

    &.nav-pills-dark {
        .nav-link {
            color: $dark;

            &.active {
                color: white;
                background-color: $dark;
            }
        }
    }

    .nav-link {
        border-radius: 0;
        font-weight: 600;
        padding: 1rem 1.15rem;
        line-height: 1;
    }
}

.w-fit-content {
    width: fit-content;
}

//
// Navbar
//

nav.navbar-light {
    font-size: .9rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }

    .navbar-brand {
        img {
            height: 45px;

            @include media-breakpoint-down(sm) {
                height: 40px;
            }
        }
    }

    .navbar-nav {
        align-items: center;

        @include media-breakpoint-down(md) {
            padding-bottom: 1rem;
            align-items: inherit;
        }

        .nav-link {
            color: $secondary;
            font-weight: 700;
            padding-left: 1.25rem;
            padding-right: 1.25rem;

            &:hover {
                color: $info !important;
            }

            &.active {
                color: $info !important;
            }

            @include media-breakpoint-down(md) {
                padding: .75rem .5rem;
            }

            &.btn-primary {
                color: white !important;

                &:hover {
                    color: white !important;
                }
            }
        }
    }

    .navbar-toggler {
        border: none;

        &:focus {
            box-shadow: none;
        }
    }

    .offcanvas-top {
        height: fit-content;
    }

    .offcanvas-header {
        padding: 1rem 1.5rem;
    }

    .offcanvas-body {
        padding: 1rem;
    }

    .dropdown {
        .dropdown-toggle {
            padding-right: .5rem;

            &::after {
                display: none;
            }
        }

        .nav-link {
            color: $secondary !important;
            font-weight: 700;

            &:hover {
                color: $info !important;
            }

            &:focus-visible {
                outline: none;
            }
        }

        .dropdown-menu {
            box-shadow: $box-shadow;
            border-radius: 0;
            border: none;

            @include media-breakpoint-up(md) {
                width: calc(100% - 1.5rem);
                margin-left: 1rem;
                margin-right: .5rem;
            }

            .dropdown-item {
                font-size: .9rem;
                font-weight: 600;
                color: $secondary;
                padding-top: .6rem;
                padding-bottom: .6rem;

                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }

                &:active {
                    background-color: $light !important;
                }
            }
        }

        .dropdown-divider {
            border-top-color: $gray-400;
        }
    }
}

//
// Footer
//

footer {
    background-color: white;
    margin-top: 3rem;
    padding: 3rem 0;
    font-size: .9rem;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .footer-link {
        color: $secondary;
        text-decoration: none;
        margin-bottom: .5rem;

        &:hover {
            color: $info;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }
}

.flip-h {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

//
// User content
//

// #order-step-2, #order-step-3 {
//     display: none;
// }

.product-card {
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;

    .product-card-left {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            margin-bottom: 3rem;
        }
    }

    .product-video-link {
        margin-top: 1rem;
        text-decoration: underline;
        font-weight: 400;
        color: $secondary !important;

        &:hover {
            color: $info;
        }
    }

    .product-card-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    hr {
        width: calc(100% + 2rem);
        margin-bottom: 1.75rem;
    }
}

.order-card {
    // position: sticky;
    // top: 30px;
    // z-index: 1020;

    .order-table {
        padding: .875rem;
        border: 1px solid $gray-200;
        overflow: hidden;
    }

    .order-row {
        margin-bottom: 1.5rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: calc(100% + 1rem);
            left: 0;
            bottom: -12px;
            border-bottom: 1px solid $gray-200;
        }

        &:last-of-type {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }
    }

    .order-product-name {
        font-weight: 600;
    }

    .order-product-id {
        font-size: 0.875rem;
    }

    .order-table-left {
        display: flex;
        align-items: center;
    }

    .send-order-btn {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: .5px;
        width: 100%;
    }
}

.order-success-card {
    max-width: 500px;

    .card-body {
        padding: 2.5rem;
    }

    p {
        font-size: 1.1rem;
        margin-bottom: 2.5rem;
    }

    .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.auth-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-image: url('/images/auth-aside.jpg');
    background-position: center;
    background-size: cover;

    &.auth-wrapper-sm {
        .auth-box {
            max-width: 480px;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

    }

    &.auth-wrapper-lg {
        .auth-box {
            max-width: 1000px;

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }
    }

    .auth-logo {
        height: 50px;
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-between;

        img {
            height: 100%;
        }

        a {
            height: 100%;
        }
    }

    .auth-box {
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3rem;

        @include media-breakpoint-down(md) {
            padding: 2rem;
        }
    }

    .auth-footer {
        margin-top: 6rem;
        text-align: center;

        .footer-link {
            margin-left: 1rem;
            font-weight: 600;
            text-decoration: none;
        }
    }

    .heading-row {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(sm) {
            .col-12:first-of-type {
                order: 1;
            }
        }

        .display-heading {
            margin-bottom: 0;
        }

        .caption {
            color: $info;
            text-transform: uppercase;
            letter-spacing: 0.75px;
            font-weight: 700;
            font-size: 14px;
        }
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

#mobileOrderOffCanvas {
    @include media-breakpoint-down(lg) {
        height: auto;
        padding-left: 0;
        padding-right: 0;
        z-index: 100;
        margin-top: 5rem;
    }

    .btn-close {
        display: block;
        position: absolute;
        top: 2rem;
        right: 1.75rem;
        z-index: 1000;
    }

}

#orderHeader {
    background-color: white;
    z-index: 1;

    @include media-breakpoint-down(md) {
        padding-top: 3rem;
    }
}

#mobileOrderOnCanvas {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 75px;
    height: 75px;
    z-index: 10;

    background-color: $primary;
    box-shadow: $box-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 1.5rem;
    transition: transform .25s;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

//
// Registration
//

.registration-flow {
    .two-step-flow-item {
        display: none;
    }

    .registration-step-2 {
        display: none;
    }

    &.two-step-flow {
        .two-step-flow-item {
            display: block;
        }

        .one-step-flow-item {
            display: none;
        }
    }


    &.step-2 {
        .registration-step-1 {
            display: none;
        }

        .registration-step-2 {
            display: block;
        }
    }
}

//
// Admin zone general
//

.admin-wrapper {
    display: flex;
    min-height: calc(100vh - $navbar-height);
    background-color: $bg-light-gray;
    padding-top: 3rem;
    font-size: 95%;

    .admin-row {
        height: 100%;
        margin-bottom: 80px;

        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    .admin-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header-row {
            justify-content: space-between;
            margin-bottom: 38px;
            align-items: center;
            margin-top: -6px;

            &.no-cta {
                margin-top: 5px;
                margin-bottom: 3rem;
            }

            .btn-sm {
                margin-top: -2px;
            }

            .btn-light-secondary.btn-center {
                @include media-breakpoint-down(sm) {
                    height: 40px;
                }
            }
        }
    }

    .card {
        border: none;

        .card-body-admin {
            padding: 1.5rem;
        }
    }

    .btn {
        font-size: inherit;
        line-height: 1;
    }

    .admin-side-col {
        //position: sticky;
        //top: 2rem;
        margin-top: 5px;

        @include media-breakpoint-down(md) {
            position: inherit;
        }

        .admin-menu-icon {
            width: 25px;
        }
    }
}

//
// Admin zone - Products
//

.admin-product-card {
    margin-bottom: 1rem;
    overflow: hidden;

    .card-body {
        @include media-breakpoint-down(lg) {
            padding-bottom: .75rem;
        }
    }

    .admin-product-card-img {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 140px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 1.5rem;
            max-width: none;
        }
    }

    .admin-product-card-texts {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .display-heading {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
    }

    .admin-product-card-actions {
        display: flex;
        align-items: center;
        position: relative;

        @include media-breakpoint-down(lg) {
            margin-top: 1.5rem;
            padding-top: .5rem;
            padding-bottom: 1rem;
        }

        .btn {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
    }
}

.edit-product-modal {
    .product-edit-right-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .product-img {
        max-width: 100%;
        height: auto;
        max-height: 250px;
        margin-left: auto;
        margin-right: auto;
        padding: 3rem;
    }
}


//
// Admin zone - Reservations
//

.reservation-card, .user-reservation-card {

    &.reservation-in-process {
        .reservation-badge {
            color: $warning;
            background-color: $btn-light-warning;
            border: 1px solid rgba($warning, .3);
        }
    }

    &.reservation-confirmed, &.reservation-returned {
        .reservation-badge {
            color: $success;
            background-color: $btn-light-success;
            border: 1px solid rgba($success, .3);
        }
    }

    &.reservation-sent-to-customer, &.reservation-sent-back {
        .reservation-badge {
            color: $info;
            background-color: $btn-light-info;
            border: 1px solid rgba($info, .3);
        }
    }

    &.reservation-delivered {
        .reservation-badge {
            color: $secondary;
            background-color: $btn-light-secondary;
            border: 1px solid rgba($secondary, .3);
        }
    }

    &.reservation-finished {
        .reservation-badge {
            color: $dark;
            background-color: $btn-light-dark;
            border: 1px solid rgba($dark, .3);
        }
    }
}

.reservation-finished, .reservation-cancelled {
    .btn-edit {
        display: none;
    }
}

.reservation-card {
    color: inherit !important;
    text-decoration: inherit;
    transition: box-shadow .25s cubic-bezier(0,0,.5,1);
    margin-bottom: .75rem;

    &:hover {
        box-shadow: $box-shadow;
    }


    &.reservation-cancelled {
        .reservation-badge {
            color: $danger;
            background-color: $btn-light-danger;
            border: 1px solid rgba($danger, .3);
        }
    }

    .card-body {
        padding: 1.5rem;
    }

    .order-number {
        font-weight: 600;
        color: $secondary;
        font-size: 1.1rem;
    }

    .order-person {
        @include media-breakpoint-down(sm) {
            text-align: right;
        }
    }

    .reservation-badge {
        @include media-breakpoint-down(xl) {
            margin-left: auto;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-top: .75rem;
        }
    }

    .bottom-row {
        @include media-breakpoint-down(xl) {
            margin-top: 1.25rem;
            padding-top: 1.25rem;
            border-top: 1px solid $gray-200;
        }

        .row {
            justify-content: space-between;
        }
    }
}

.reservation-badge {
    color: $dark;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    line-height: 1;
    width: fit-content;

    &.reservation-in-process {
        color: $warning;
        background-color: $btn-light-warning;
        border: 1px solid rgba($warning, .3);
    }

    &.reservation-confirmed, &.reservation-returned {
        color: $success;
        background-color: $btn-light-success;
        border: 1px solid rgba($success, .3);
    }

    &.reservation-sent-to-customer, &.reservation-sent-back {
        color: $info;
        background-color: $btn-light-info;
        border: 1px solid rgba($info, .3);
    }

    &.reservation-delivered {
        color: $secondary;
        background-color: $btn-light-secondary;
        border: 1px solid rgba($secondary, .3);
    }

    &.reservation-finished {
        color: $dark;
        background-color: $btn-light-dark;
        border: 1px solid rgba($dark, .3);
    }
    &.reservation-cancelled {
        color: $danger;
        background-color: $btn-light-danger;
        border: 1px solid rgba($danger, .3);
    }
}

.edit-reservation-products-modal {
    .bootstrap-touchspin {
        align-items: center;

        @include media-breakpoint-down(sm) {
            margin-top: 1rem;
        }

        .btn, input {
            background-color: transparent !important;
            padding-top: 0;
            padding-bottom: .25rem;
            display: flex;
            align-items: center;
        }
    }

    .bootstrap-touchspin-up {
        padding-right: .25rem;
    }

    .bootstrap-touchspin-down {
        padding-left: 0;
    }

    .stock-status {
        color: $gray-500;
        font-size: .75rem;
        text-align: right;
        padding-right: .35rem;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    .order-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .order-table-left {
        @include media-breakpoint-down(sm) {
            margin-top: -12px;
            margin-left: -20px;
        }
    }

    .order-row.out-of-stock {
        .bootstrap-touchspin-up {
            pointer-events: none;

            i {
                opacity: .5;
            }
        }

        .stock-status {
            color: $danger;
        }
    }
}

//
// Users
//

.reservation-card-user {
    border: 1px solid $gray-200 !important;
}

.user-reservation-card {
    margin-bottom: 1rem;

    .card-left-col {
        height: 100%;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            width: calc(100% + 24px);
        }

        .row {
            width: 100%;
        }
    }

    .order-number {
        font-weight: 600;
        color: $secondary;

        @include media-breakpoint-down(lg) {
            text-align: right;
        }
    }

    .order-card.order-card-simple {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        width: 100%;
        font-size: .875rem;

        .order-table {
            width: 100%;
        }

        .note-card {
            margin-right: 70px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .order-row {
            margin-bottom: 0.25rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            &::after {
                display: none;
            }
        }
    }
}

//
// Documents
//

.document-card {
    text-decoration: none;
    transition: box-shadow .25s;
    height: fit-content;

    &:hover {
        box-shadow: $box-shadow;
    }

    @include media-breakpoint-down(md) {
        height: inherit;
    }

    i {
        color: rgba($secondary, .25);
    }
}

.page-editor-wrapper {
    .ql-toolbar, .ql-container {
        background-color: white;
        border: none !important;
    }

    .ql-toolbar {
        padding: 1rem !important;
        border: none !important;
        border-bottom: 1px solid #e9ecef !important;
    }

    .ql-container {
        padding: .6rem;
    }
}

//
// Bootstrap Touchspin
//

.bootstrap-touchspin {
    .btn, input {
        background-color: $input-bg !important;
        display: flex;
        align-items: center;
    }

    .bootstrap-touchspin-down {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        padding-right: 0;
        height: 47px !important;
    }

    .bootstrap-touchspin-up {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-left: 0;
        height: 47px !important;

        &::before {
            content: 'ks';
            color: $gray-600;
            margin-right: 15px;
        }
    }

    .bootstrap-touchspin-up, .bootstrap-touchspin-down {
        &.not-allowed {
            cursor: not-allowed;
            i {
                opacity: .35;
            }
        }
    }

    .form-control.form-control-touchspin {
        width: 32px;
        text-align: right;
        padding-left: 10px;
        padding-right: 2px;
        pointer-events: none;
        font-weight: 600;
        color: $dark;
        -webkit-text-fill-color: $dark;


        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}

 //
// alerts
//

.swal2-actions {
    button {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.alert-dismissible .btn-close {
    font-size: .875rem;
}

//
// Pagination
//
.pagination {
    margin-top: 32px;
    
    .page-link {
        border: none;
        padding: 0.5rem 1rem !important;
    }
}


//

//
// About
//

.logos-wrap {

}
